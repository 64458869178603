/* App.css */
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
/* Primary Color: Duck Yellow */
:root {
  --primary-color: #B3BFAA; /* Subtle Olive Green */
  --primary-color-hover: #9EA994;
  --secondary-color: #F2E9BB; /* Soft Beige */
  --secondary-color-hover: #D8CFA0;
  --accent-color: #F2B705; /* Vibrant Yellow */
  --accent-color-hover: #E0A904;
  --background-color: #FFFFFF; /* Soft Beige Background */
  --alternate-background-color: #F7F6E6; /* Very Light Beige */
  --text-color: #2E2E2E; /* Dark Charcoal */
  --success-color: #BBC0AB; /* Subtle Olive Green */
  --success-color-hover: #A0A58E;
  --font-family: "Poppins", sans-serif;
}




/* Global Styles */
body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: var(--font-family);
  margin: 0;
  padding: 0;
}

a {
  color: var(--secondary-color);
  text-decoration: none;
}

a:hover {
  color: var(--accent-color);
}

/* Buttons */
.btn-primary {
  background-color: var(--primary-color);
  border: none;
  color: white;
}

.btn-primary:hover {
  background-color: var(--primary-color-hover);
}

.btn-success {
  background-color: var(--success-color);
  border: none;
  color: white;
}

.btn-success:hover {
  background-color: darken(var(--success-color), 10%);
}

/* Headers */
h1, h2, h3, h4, h5, h6 {
  color: var(--text-color);
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}
p, span{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

/* Additional CSS for other elements remains unchanged */
.duck-icon {
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
  margin-right: 10px;
  display: inline-block;
}

.Appbar {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.start-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}